import React, { Component } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import AOS from "aos"
import 'aos/dist/aos.css';
import newsArticleOne from "../../images/news/emmerdale-studio-experience.jpg"
import NewsStrip from "../../components/news-strip"

class NewsPage extends Component {

  componentDidMount(){
    AOS.init();
    window.addEventListener('load', AOS.refresh);
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000);
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Tetley's operate Emmerdale shuttle service" description="Tetley's coaches are delighted to be working with The Emmerdale studio experience providing a shuttle service
every Saturday and Sunday between Burley Road Studios and the ITV Television studio." />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent page-article" style={{ background: 'white' }}>
            <div>
              <h1 className="page-content-heading">Tetley's operate Emmerdale shuttle service</h1>
              <p className="news-article-date">April 2019</p>
            </div>

            <div className="news-article-body">
              <p className="news-article-img">
                <img src={newsArticleOne} alt="" />
              </p>
              <p>
              We are delighted to be working with The Emmerdale studio experience providing a shuttle service
every Saturday and Sunday between Burley Road Studios and the ITV Television studio. 2 Optare
solos assist with the transporting of passengers between both sites.</p>
<p>For more information on the
Emmerdale studio experience <a href="https://www.emmerdalestudioexperience.co.uk/studio-experience-tour/">click here</a>.</p>
            </div>
          </div>
        </div>

        <NewsStrip />

      </Layout>
    );
  }
}

export default NewsPage
